var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "timeline", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Timelines", link: "components/timelines" }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-timeline",
                { attrs: { "align-top": "" } },
                _vm._l(_vm.timelines, function(timeline, i) {
                  return _c(
                    "v-timeline-item",
                    {
                      key: i,
                      attrs: {
                        color: timeline.color,
                        icon: timeline.icon,
                        "fill-dot": "",
                        large: ""
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-6" },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "overline mb-3",
                              attrs: { color: timeline.color, small: "" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(timeline.chip) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c("p", {
                            staticClass: "subtitle-1 font-weight-light",
                            domProps: { textContent: _vm._s(timeline.text) }
                          }),
                          _c("div", {
                            staticClass: "text-uppercase body-2",
                            domProps: { textContent: _vm._s(timeline.subtext) }
                          }),
                          timeline.action
                            ? [
                                _c("v-divider", { staticClass: "mb-3" }),
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "offset-y": "",
                                      origin: "top left",
                                      right: "",
                                      transition: "scale-transition"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var attrs = ref.attrs
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: timeline.action,
                                                        large: "",
                                                        rounded: ""
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", {
                                                    attrs: { left: "" },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        timeline.actionIcon
                                                      )
                                                    }
                                                  }),
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { right: "" } },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.menu
                                                              ? "mdi-menu-up"
                                                              : "mdi-menu-down"
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.menu,
                                      callback: function($$v) {
                                        _vm.menu = $$v
                                      },
                                      expression: "menu"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-sheet",
                                      [
                                        _c(
                                          "v-list",
                                          _vm._l(timeline.actions, function(a) {
                                            return _c(
                                              "v-list-item",
                                              { key: a, attrs: { link: "" } },
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    textContent: _vm._s(a)
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }